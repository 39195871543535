export enum Screen {
  XlScreen = 'XlScreen',
  LgScreen = 'LgScreen',
  MdScreen = 'MdScreen',
  SmScreen = 'SmScreen',
  XsScreen = 'XsScreen',
}

export const Breakpoints = {
  [Screen.XlScreen]: 1401,
  [Screen.LgScreen]: 1400,
  [Screen.MdScreen]: 1199,
  [Screen.SmScreen]: 1023,
  [Screen.XsScreen]: 704,
}

export const getScreenSize = (screen: Screen) => {
  switch (screen) {
    case Screen.XlScreen:
      return ({ minWidth: Breakpoints.XlScreen });
    case Screen.LgScreen:
      return ({ maxWidth: Breakpoints.LgScreen });
    case Screen.MdScreen:
      return ({ maxWidth: Breakpoints.MdScreen });
    case Screen.SmScreen:
      return ({ maxWidth: Breakpoints.SmScreen });
    case Screen.XsScreen:
      return ({ maxWidth: Breakpoints.XsScreen });
    default:
      return ({});
  }
}
