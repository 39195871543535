import {apiCall} from "utils/api";
import { userId } from 'utils/auth';

const HOST = `${process.env.NEXT_PUBLIC_API_HOST}/sessions/users`;

export const confirmLogout = async () => {
  const res = await apiCall({
    method: 'delete',
    headers: { 'business-type': 'shopifyDropshippingPlugin' },
    url: `${HOST}/${userId()}`,
  });

  return res.data;
};
