import {isStaff, staffEmail} from "../utils/auth";
import {sessionLogout, staffSessionLogout} from "./login";
import deleteAllCookies from "./killCookies";

const handleLogout = (router: any) => {
  const email =staffEmail();
  if(isStaff()){
    staffSessionLogout(
      () => {
        deleteAllCookies();
        router.push('/staff-login');
      },email
    );
  }else{
    sessionLogout(
      () => {
        deleteAllCookies();
        router.push('/login');
      }
    );
  }

};
export default handleLogout;