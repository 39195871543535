import moment from "moment";
import { useEffect, useState } from "react";

/**
 * 
 * @param message String
 * @param expiryDateTime String: YYYY-MM-DD HH:mm:ss
 * @returns 
 */
const RedAlert = ({ message, expiryDateTime }: { message: string, expiryDateTime: string }) => {
  const [showAlert, setAlert] = useState(false);

  useEffect(() => {
    if(isAfterSpecifiedDateTime(expiryDateTime)) setAlert(false);
    else setAlert(!!expiryDateTime);
  }, []);

  function isAfterSpecifiedDateTime(specifiedDateTime: any) {
    return moment(moment().format("YYYY-MM-DD HH:mm:ss")).isAfter(specifiedDateTime);
  }

  if (!showAlert) return <></>;

  return (
    <div className="w-full bg-[red] flex justify-center items-center text-white text-sm">
      <div className="flex-1 text-center p-2">{message}</div>
    </div>
  );
}

export default RedAlert;
